@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.panel-outer {
  .panel {
    position: relative;
    &:not(:last-child){
      margin-bottom: calc(var(--space-sm) * var(--text-vspace-multiplier));
    }

    &__header {
      display: block;
      padding: var(--space-md) 0;
    }

    &__title {
      position: relative;
      margin: 0;
      padding-right: 50px;
      color: inherit;

      &:after {
        content: '\e95d';
        position: absolute;
        pointer-events: none;
        top: 50%;
        right: 0;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: var(--font-icon);
        font-size: var(--text-md);
        border: 1px solid rgba(0,0,0,.2);
        border-radius: 50%;
        transform: translateY(-50%);
      }
    }

    &__teaser {
      margin-bottom: 0;
      margin-top: var(--space-md);
      color: var(--color-text);
      line-height: var(--component-heading-line-height);
    }

    &__content {
      display: none;
      overflow: hidden;
      opacity: 0;
      padding: var(--space-sm) 0;
      pointer-events: none;
      //transform: translateY(20px);
      transition:
        transform .4s cubic-bezier(0.23, 1, 0.32, 1),
        opacity .4s cubic-bezier(0.23, 1, 0.32, 1);

      // Custom scrollbar
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background: transparent;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: transparent;
      }

    }

    &__block {
      padding: 0 var(--space-md);

      &:not(:last-child){
        padding-bottom: var(--space-md);
      }
    }
  }

  // Modifier
  &.is {
    &--accordion {
      .panel {
        &:not(:last-child){
          margin-bottom: 0;
          border-bottom: 1px solid rgba(0,0,0,.2);
        }
      }
    }
  }

  .is {
    // Show hidden content
    &--active {
      .panel {
        &__content {
          display: block;
          pointer-events: all;

          // Hover scrollbar
          &:hover {
            &::-webkit-scrollbar-track {
              background: #f1f1f1;
            }
            &::-webkit-scrollbar-thumb {
              background: #888;
            }
            &::-webkit-scrollbar-thumb:hover {
              background: #555;
            }
          }
        }

        &__title {
          &:after {
            content: '\e94e';
          }
        }
      }
    }

    // For animating
    &--visible {
      .panel {
        &__content {
          opacity: 1;
          transform: none;
        }
      }
    }

  }
}
